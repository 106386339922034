import React, { useState } from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import Section from 'components/Section';
import Box from 'components/Box';
import ButtonCTA from 'components/ButtonCTA';
import { EMAIL_REGEX } from 'utils';

import { Trans, useTranslation } from 'react-i18next';

const ContentWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 80px;
  color: ${(props) => props.theme.colors.tertiary};
  background-color: ${(props) => props.theme.colors.secondary};

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    opacity: 0.7;
  }
`;

const SectionHeading = styled.h2`
  font-size: ${rem(32)};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${rem(46)};
  margin-bottom: 16px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(26)};
    line-height: ${rem(39)};
  }
`;

const SectionSubtitle = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  text-align: center;
`;

const ButtonWrapper = styled.span`
  background-color: rgba(66, 66, 66, 0.07);
  padding: 4px 4px 4px 16px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    background-color: transparent;
    padding: 0;
  }

  button {
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      width: 100%;
      padding: 12px 93px;
    }
  }
`;

const EmailForm = styled.form`
  display: flex;

  width: 100%;

  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: 16px;
  }

  input {
    background-color: rgba(66, 66, 66, 0.07);
    padding: 4px 4px 4px 16px;
    flex: 1;

    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;

    border: none;

    font-size: ${rem(16)};
    line-height: ${rem(24)};
    font-weight: ${(props) => props.theme.fontWeights.semibold};

    color: rgba(66, 66, 66, 0.5);

    ::placeholder {
      color: rgba(66, 66, 66, 0.5);
    }

    :focus {
      outline: none;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      width: 100%;
      border-radius: 8px;
      padding: 12px 120px;
      text-align: center;
    }
  }
`;

const ErrorMessage = styled.p`
  font-size: ${rem(12)};
  line-height: ${rem(21)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  color: red;
  opacity: 0.7;
  margin-top: 8px;
`;

const DirectSignup = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email || !EMAIL_REGEX.test(email)) {
      setError(t('invalid.email.error'));
      return;
    }

    const encondedEmail = encodeURIComponent(email);
    setError('');

    window.open(
      `https://app.secureuserarea.com/register/email?profileType=personal&email=${encondedEmail}`,
    );
  };

  return (
    <ContentWrapper>
      <Section noDivider>
        <Box>
          <SectionHeading>
            <Trans i18nKey="how_it_works.CTA.Content.Ready_to_open_your_account?" />
          </SectionHeading>
          <SectionSubtitle>
            {t('how_it_works.CTA.Content.Title')}
          </SectionSubtitle>
          <EmailForm onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              id="email"
              placeholder={t('how_it_works.CTA.Content.Input.Your_email')}
              onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
            />
            <ButtonWrapper>
              <ButtonCTA goldHover>{t('home.CTA.Open_an_account')}</ButtonCTA>
            </ButtonWrapper>
          </EmailForm>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Box>
      </Section>
    </ContentWrapper>
  );
};

export default DirectSignup;
